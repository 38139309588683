import axios from "axios";
import React, { useState } from "react";

function Contactus() {
  const [formdata, setformData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formdata, [name]: value });
  };
  console.log(formdata);

  const submitdata = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://sheet.best/api/sheets/cffdd959-0a03-4b25-a2d2-30b98a145a7d",
        formdata
      )
      .then((response) => {
        console.log(response);
        setResponseMessage("Your message has been sent successfully!");
        setformData(formdata);
      })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
        setResponseMessage("There was an error submitting your message.");
      });
  };

  return (
    <>
      <div className="container-fluid service">
        <div className="container">
          <div className="service-content">
            <h1>contact us</h1>
          </div>
        </div>
        <div className="slider-six">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 122"
            className="vector-img"
          >
            <path
              fill="currentColor"
              d="M0,122.005S80.814,20.129,283.871,2.426C526.255-18.706,550.388,91.2,792.025,102.115c243.565,11.006,299.815-108,520.765-97.662,220.1,10.3,273.86,88.036,415.09,98.26C1878.57,113.62,1920,51.068,1920,51.068V-862.995H0v985Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container contactus">
        <form className="contact-form" onSubmit={submitdata}>
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                name="firstname"
                placeholder="First Name"
                value={formdata.firstname}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="lastname"
                placeholder="Last Name"
                value={formdata.lastname}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formdata.email}
                onChange={handleChange}
                required
              />
            </div>
            {/* <div className="col-md-6">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formdata.password}
                onChange={handleChange}
                required
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <textarea
                name="message"
                placeholder="Write your message"
                rows="6"
                value={formdata.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
          <div className="form-btn-div">
            <button className="form-btn" type="submit">
              Send message
            </button>
          </div>
          {responseMessage && (
            <p className="response-message">{responseMessage}</p>
          )}
        </form>
      </div>
    </>
  );
}

export default Contactus;
