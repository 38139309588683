import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleNavLinkClick = () => {
    setIsNavOpen(false);
  };

  return (
    <header className="header">
      <div className="container-fluid">
        <div className="container header-content">
          <div className="logo">
            <Link to="/">
              <img src="images/logo.png" alt="Logo" className="header-logo" />
            </Link>
          </div>
          <nav className={`nav ${isNavOpen ? "open" : ""}`}>
            <ul className="nav-list">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  exact
                  onClick={handleNavLinkClick}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/service"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  href="/#portfolio"
                  className="nav-link"
                  onClick={handleNavLinkClick}
                >
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/aboutus"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  About Us
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="contact-button">
            <NavLink
              to="/contactus"
              className="contact-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              Contact Us
            </NavLink>
          </div>
          <div className="hamburger" onClick={handleNavToggle}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
