import React from "react";
import { aboutdata } from "../api/data";

function Aboutus() {
  return (
    <>
      <div className="container-fluid about">
        <div className="container">
          <div className="about-content">
            <h1>about us</h1>
          </div>
        </div>
        <div className="slider-six">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 122"
            className="vector-img"
          >
            <path
              fill="currentColor"
              d="M0,122.005S80.814,20.129,283.871,2.426C526.255-18.706,550.388,91.2,792.025,102.115c243.565,11.006,299.815-108,520.765-97.662,220.1,10.3,273.86,88.036,415.09,98.26C1878.57,113.62,1920,51.068,1920,51.068V-862.995H0v985Z"
            ></path>
          </svg>
        </div>
      </div>

      <div className="container-fluid about-section-vection">
        <div className="container about-section">
          {aboutdata.map((item, index) => (
            <div
              className={`row ${index % 2 === 1 ? "row-reverse" : ""}`}
              key={item.id}
            >
              <div className="col-md-6">
                <div className="about-img-div">
                  <img
                    src={item.image}
                    alt="aboutimage"
                    className="about-img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-section-content">
                  <h3>{item.heading}</h3>
                  <p>{item.text}</p>
                  <ul>
                    <li>{item.li1}</li>
                    <li>{item.li2}</li>
                    <li>{item.li3}</li>
                    <li>{item.li4}</li>
                    <li>{item.li5}</li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Aboutus;
