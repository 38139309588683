import React, { useState } from "react";
import axios from "axios";

function Career() {
  const [careerdata, setcareerData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    designation: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate phone number
    if (name === "phone") {
      // Allow only digits and special characters, and limit length to 14
      const phoneRegex = /^[0-9-+()\s]*$/;
      if (value.length > 14 || !phoneRegex.test(value)) {
        return; // Invalid input, do not update state
      }
    }

    setcareerData({ ...careerdata, [name]: value });
  };

  const submitdata = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://sheet.best/api/sheets/a9910b70-ab07-49e4-b753-dfaf6dc3ddcd",
        careerdata
      )
      .then((response) => {
        console.log(response);
        setResponseMessage("Your message has been sent successfully!");
        setcareerData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          designation: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
        setResponseMessage("There was an error submitting your message.");
      });
  };

  return (
    <>
      <div className="container-fluid service">
        <div className="container">
          <div className="service-content">
            <h1>careers</h1>
          </div>
        </div>
        <div className="slider-six">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 122"
            className="vector-img"
          >
            <path
              fill="currentColor"
              d="M0,122.005S80.814,20.129,283.871,2.426C526.255-18.706,550.388,91.2,792.025,102.115c243.565,11.006,299.815-108,520.765-97.662,220.1,10.3,273.86,88.036,415.09,98.26C1878.57,113.62,1920,51.068,1920,51.068V-862.995H0v985Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container contactus">
        <form className="contact-form" onSubmit={submitdata}>
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                name="firstname"
                placeholder="First Name"
                required
                value={careerdata.firstname}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="lastname"
                placeholder="Last Name"
                required
                value={careerdata.lastname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                value={careerdata.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                required
                value={careerdata.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                name="designation"
                placeholder="Designation"
                required
                value={careerdata.designation}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <textarea
                name="message"
                placeholder="Write your message"
                rows="6"
                required
                value={careerdata.message}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <div className="form-btn-div">
            <button className="form-btn" type="submit">
              Send message
            </button>
          </div>
          {responseMessage && (
            <p className="response-message">{responseMessage}</p>
          )}
        </form>
      </div>
    </>
  );
}

export default Career;
