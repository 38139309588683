import "./App.css";
import Header from "./common/Header";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Service from "./pages/Service";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import Career from "./pages/Career";
import Footer from "./common/Footer";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/aboutus" element={<Aboutus />} />
          <Route exact path="/contactus" element={<Contactus />} />
          <Route exact path="/career" element={<Career />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
