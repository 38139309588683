export const homeslider = [
  {
    id: 1,
    bgimage: "images/slider.jpg",
    title: "Elevate Your Content with Us!",
    subtitle: "djwfjefbh",
  },
  {
    id: 2,
    bgimage: "images/slider2.jpg",
    title: "Empowering Your Digital Presence.",
    subtitle: "djwfjefbh",
  },
  {
    id: 3,
    bgimage: "images/slider3.jpg",
    title: "Your Growth, Our Mission.",
    subtitle: "djwfjefbh",
  },
];

export const ourpartners = [
  {
    id: 1,
    image: "images/partner1.png",
  },
  {
    id: 2,
    image: "images/partner2.png",
  },
  {
    id: 3,
    image: "images/partner3.png",
  },
  {
    id: 4,
    image: "images/partner4.png",
  },
  {
    id: 5,
    image: "images/partner5.png",
  },
  {
    id: 6,
    image: "images/partner6.svg",
  },
];

export const aboutdata = [
  {
    id: 1,
    image: "images/about.png",
    heading: "Got A Startup Idea?",
    text: "Turn your startup idea into a reality with our comprehensive services. We can help elevate your business with:",
    li1: "Developing a solid business plan and strategy.",
    li2: "Creating a strong brand identity with logos and branding materials.",
    li3: "Building a professional and user-friendly website.",
    li4: "Increasing your online presence with effective marketing.",
    li5: "Engaging your audience with high-quality content and videos",
  },
  {
    id: 2,
    image: "images/about2.png",
    heading: "Small Business?",
    text: "Promoting a small business effectively involves a mix of digital marketing, networking, and customer engagement strategies. Here are some key tactics:",
    li1: "Create engaging content and utilize paid ads on social media platforms.",
    li2: "Improve search engine rankings with relevant keywords and local SEO.",
    li3: "Publish blog posts and create videos to attract and engage your audience.",
    li4: "Build an email list and send newsletters with updates and promotions.",
  },
  {
    id: 3,
    image: "images/about3.png",
    heading: "Easy Scheduling",
    text: "Easy Scheduling and Quick Service we make it simple for you to get the professional services you need. Scheduling a meeting with our experts is easy and convenient.",
    li1: "Easy Scheduling: Book a consultation through our website with just a few clicks.",
    li2: "Talk to Professionals: Speak directly with our experienced team to discuss your needs.",
    li3: "Quick Turnaround: Get everything done within a week, ensuring your business doesn't miss a beat.",
  },
  {
    id: 4,
    image: "images/about4.png",
    heading: "Make It Organic",
    text: "Simplify your brand’s processes and embrace organic growth with ease. Here’s how we help you achieve this:",
    li1: "Streamlined solutions to meet your brand’s needs without unnecessary complexity.",
    li2: "Focus on natural, sustainable growth strategies that build trust and authenticity with your audience.",
    li3: "User-friendly processes designed to be intuitive and easy to implement, ensuring a hassle-free experience.",
    li4: "Consistency across all aspects of your brand, fostering long-term success.",
  },
];

export const serviceData = [
  {
    id: 1,
    heading: "Video Editing",
    text: "We offer professional video editing services for YouTube, TikTok, Instagram, and more. Our expertise includes:",
    li1: "B-Roll Integration: Enhance your videos with dynamic and engaging secondary footage.",
    li2: "Sound Effects (SFX): Add impactful sound effects to elevate your content.",
    li3: "Motion Graphics: Incorporate eye-catching animations and graphics.",
    li4: "Background Music (BGM): Choose the perfect background music to complement your video.",
  },
  {
    id: 2,
    heading: "Content Writing",
    text: "We specialize in:",
    li1: "SEO Optimization: Boost your visibility with keyword-rich content.",
    li2: "Blog Posts: Engage your audience with informative and well-researche  articles.",
    li3: "Web Copy: Craft compelling and persuasive website copy.",
    li4: "Product Descriptions: Highlight your products with clear and enticing descriptions",
  },
  {
    id: 3,
    heading: "Graphic Designing",
    text: "Enhance your brand’s visual appeal with our expert graphic design services. We provide:",
    li1: "Logo Design: Create a memorable and unique brand identity.",
    li2: "Social Media Graphics: Design eye-catching visuals for your social media platforms.",
    li3: "Marketing Materials: Develop impactful flyers, brochures, and posters.",
    li4: "Custom Illustrations: Bring your ideas to life with personalized illustrations.",
  },

  {
    id: 4,
    heading: "Web Development",
    text: "Build a robust online presence with our professional web development services. We offer:",
    li1: "Custom Websites: Design and develop unique, user-friendly websites.",
    li2: "Shopify/E-commerce: Create seamless online shopping experiences.",
    li3: "Responsive Design: Ensure your website looks great on all devices.",
    li4: "SEO Integration: Optimize your site for search engine visibility.",
  },

  {
    id: 5,
    heading: "SEO",
    text: "Maximize your online visibility with our expert SEO services. We specialize in:",
    li1: "YouTube Videos: Optimize titles, descriptions, and tags for higher search rankings.",
    li2: "Instagram Reels: Use effective hashtags and descriptions to increase engagement.",
    li3: "TikTok: Implement strategies to boost discoverability and reach a wider audience.",
    li4: "Content Strategy: Develop a tailored SEO strategy for all your content..",
  },

  {
    id: 6,
    heading: "Paid Advertisement",
    text: "Boost your online reach with our targeted paid ads services. We specialize in:",
    li1: "Google Ads: Create and manage effective campaigns to increase visibility and traffic.",
    li2: "Meta Ads: Utilize Facebook and Instagram ads to engage your target audience..",
    li3: "Ad Copywriting: Craft compelling ad copy that drives conversions.",
    li4: "Performance Tracking: Monitor and optimize ad performance for maximum ROI.",
  },
];
