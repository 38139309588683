import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { homeslider } from "../api/data";

const HomeSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {homeslider.map((slide, index) => (
          <div key={index} className="slide-wrapper">
            <div
              className="slide"
              style={{
                backgroundImage: `url(${slide.bgimage})`,
              }}
            >
              <div className="slide-content">
                <h2>{slide.title}</h2>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="slider-six">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 122"
          className="vector-img"
        >
          <path
            fill="currentColor"
            d="M0,122.005S80.814,20.129,283.871,2.426C526.255-18.706,550.388,91.2,792.025,102.115c243.565,11.006,299.815-108,520.765-97.662,220.1,10.3,273.86,88.036,415.09,98.26C1878.57,113.62,1920,51.068,1920,51.068V-862.995H0v985Z"
          ></path>
        </svg>
      </div>
      {/* <img src="images/vector.png" alt="Vector" className="vector-img" /> */}
    </div>
  );
};

export default HomeSlider;
