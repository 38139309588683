import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="container-fluid footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-logo-img">
              <img
                src="images/footerlogo.png"
                alt="Logo"
                className="footer-logo"
              />
            </div>
          </div>
          <div className="col-md-4">
            <NavLink to="/aboutus" className="footer-link">
              About Us
            </NavLink>
            <NavLink to="/service" className="footer-link">
              Services
            </NavLink>
            <NavLink to="/career" className="footer-link">
              Careers
            </NavLink>
            <NavLink to="/contactus" className="footer-link">
              Contact Us
            </NavLink>
          </div>
          <div className="col-md-4">
            <div className="footer-social-icons">
              <Link
                to="https://www.linkedin.com/company/timelanco/"
                className="footer-social-link"
                target="blank"
              >
                <FaLinkedin />
              </Link>
              <Link
                to="https://www.instagram.com/timelanco/"
                className="footer-social-link"
                target="blank"
              >
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
