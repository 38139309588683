import React from "react";
import HomeSlider from "../components/Homeslider";
import { ourpartners } from "../api/data";
import Homeportfolio from "../components/Homeportfolio";

function Home() {
  return (
    <>
      <HomeSlider />

      <div className="container-fluid partners">
        <div className="container">
          <h1 className="partner-heading">our partners</h1>
          <div className="row">
            {ourpartners.slice(0, 3).map((item, index) => (
              <div key={index} className="col-md-4">
                <div className="partner-image-div">
                  <img
                    src={item.image}
                    alt={`img-${index}`}
                    className="partner-image"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {ourpartners.slice(3).map((item, index) => (
              <div key={index} className="col-md-4">
                <div className="partner-image-div">
                  <img
                    src={item.image}
                    alt={`img-${index + 4}`}
                    className="partner-image"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-fluid  home-services">
        <h1 className="home-service-heading">services</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="home-service-content">
                <div className="home-service-img">
                  <img
                    src="images/videoedit.png"
                    alt="videoedit"
                    className="home-service-image"
                  />
                </div>
                <h4>Video Editing</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-service-content">
                <div className="home-service-img">
                  <img
                    src="images/contentwrite.png"
                    alt="videoedit"
                    className="home-service-image"
                  />
                </div>
                <h4>Content Writing</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-service-content">
                <div className="home-service-img">
                  <img
                    src="images/graphic.png"
                    alt="videoedit"
                    className="home-service-image"
                  />
                </div>
                <h4>Graphic Designing</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="home-service-content">
                <div className="home-service-img">
                  <img
                    src="images/web.png"
                    alt="videoedit"
                    className="home-service-image"
                  />
                </div>
                <h4>web development</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-service-content">
                <div className="home-service-img">
                  <img
                    src="images/seo.png"
                    alt="videoedit"
                    className="home-service-image"
                  />
                </div>
                <h4>SEO</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-service-content">
                <div className="home-service-img">
                  <img
                    src="images/paidadver.png"
                    alt="videoedit"
                    className="home-service-image"
                  />
                </div>
                <h4>paid advertisement</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="portfolio" className="container-fluid">
        <h1 className="partner-heading">portfolio</h1>
        <Homeportfolio />
      </div>
    </>
  );
}

export default Home;
