import React, { useState } from "react";
import { serviceData } from "../api/data";
import { FaPlus, FaMinus } from "react-icons/fa";

function Service() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="container-fluid service">
        <div className="container">
          <div className="service-content">
            <h1>services</h1>
          </div>
        </div>
        <div className="slider-six">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 122"
            className="vector-img"
          >
            <path
              fill="currentColor"
              d="M0,122.005S80.814,20.129,283.871,2.426C526.255-18.706,550.388,91.2,792.025,102.115c243.565,11.006,299.815-108,520.765-97.662,220.1,10.3,273.86,88.036,415.09,98.26C1878.57,113.62,1920,51.068,1920,51.068V-862.995H0v985Z"
            ></path>
          </svg>
        </div>
        {/* <img
          src="images/vector.png"
          alt="bannerimage"
          className="service-banner"
        /> */}
      </div>

      <div className="container accordion-container">
        <div className="row">
          <div className="column accordion-col">
            {serviceData.slice(0, 3).map((service, index) => (
              <div
                key={service.id}
                className={`accordion-item ${
                  activeIndex !== null && activeIndex < index ? "blurred" : ""
                }`}
              >
                <button
                  className="accordion-btn"
                  onClick={() => handleToggle(index)}
                >
                  <h3>{service.heading}</h3>
                  <span className="accordion-icon">
                    {activeIndex === index ? <FaMinus /> : <FaPlus />}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <p>{service.text}</p>
                  <ul>
                    <li>{service.li1}</li>
                    <li>{service.li2}</li>
                    <li>{service.li3}</li>
                    <li>{service.li4}</li>
                    <li>{service.li5}</li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className="column accordion-col">
            {serviceData.slice(3, 6).map((service, index) => (
              <div
                key={service.id}
                className={`accordion-item ${
                  activeIndex !== null && activeIndex < index + 3
                    ? "blurred"
                    : ""
                }`}
              >
                <button
                  className="accordion-btn"
                  onClick={() => handleToggle(index + 3)}
                >
                  <h3>{service.heading}</h3>
                  <span className="accordion-icon">
                    {activeIndex === index + 3 ? <FaMinus /> : <FaPlus />}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    activeIndex === index + 3 ? "active" : ""
                  }`}
                >
                  <p>{service.text}</p>
                  <ul>
                    <li>{service.li1}</li>
                    <li>{service.li2}</li>
                    <li>{service.li3}</li>
                    <li>{service.li4}</li>
                    <li>{service.li5}</li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
