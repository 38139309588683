import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import YouTube from "react-youtube";

const portfolioslider = [
  { id: 1, reel: "https://youtu.be/Y6_dTPnBo6g" },
  { id: 2, reel: "https://youtu.be/ALahYjq1vMI" },
  { id: 3, reel: "https://youtube.com/shorts/tMiQWrCDRzc" },
  { id: 4, reel: "https://youtube.com/shorts/McnLbdOUz4A" },
  { id: 5, reel: "https://youtube.com/shorts/kQph-qQD9YI" },
  { id: 6, reel: "https://youtube.com/shorts/tUXaaFqMw5g" },
];

const extractVideoId = (url) => {
  const urlParts = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|shorts\/)/);
  return urlParts[2] !== undefined
    ? urlParts[2].split(/[^\w-]/i)[0]
    : urlParts[0];
};

function Homeportfolio() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const createYouTubeOptions = (videoId) => ({
    height: "620",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      modestbranding: 1,
      loop: 1,
      playlist: videoId,
    },
  });

  const onReady = (event) => {
    const player = event.target;
    try {
      player.playVideo();
    } catch (error) {
      console.error("Error playing video:", error);
    }
  };

  return (
    <div className="container portfolio">
      <Slider {...settings}>
        {portfolioslider.map((slide) => (
          <div
            key={slide.id}
            className="p-2"
            style={{ width: "100%", height: "620px" }}
          >
            <YouTube
              videoId={extractVideoId(slide.reel)}
              opts={createYouTubeOptions(extractVideoId(slide.reel))}
              onReady={onReady}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Homeportfolio;
